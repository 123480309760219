// changing default color links
$link-color: $yellow !default;

.nav-link {
  cursor: pointer;
}

.banner-container {
  height: 140vh;
}

.gallery-img-container {
  height: 100%;

  img {
    height: 100% !important;
  }
}

.blog-container {
  img {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .banner-container {
    height: auto;
  }
  .gallery-img-container img {
    height: auto !important;
  }
}

// action link style
%actionMS {
  @include transition;
  display: inline-block;
  position: relative;
  padding-right: 2em;
  cursor: pointer;

  .bi {
    @include transition;
    @include translateY(-50%);
    position: absolute;
    right: 0.5em;
    top: 50%;
    display: block;
  }

  &:hover {
    .bi {
      right: 0;
    }
  }
}
